<template>
  <div class="my-pushu">
    <navbar>
      <template #right>
        <span class="link" @click="$toast('请到PC端上传谱书')">上传谱书</span>
      </template>
    </navbar>
    <van-list
      v-model="loading"
      class="result-list"
      :finished="finished"
      finished-text="没有更多了"
      @load="fetchList"
    >
      <div
        v-for="item in resultList"
        :key="item.id"
        @click="navigateToDetail(item.id)"
        class="list-item"
      >
        <div class="thumb">
          <img :src="item.cover_image" alt="" />
          <div v-if="item.status === 1" class="status status-1">已上架</div>
          <div v-else-if="item.status === 2" class="status status-2">
            下架
          </div>
          <div v-else class="status status-0">未审核</div>
          <!-- <div v-if="readed.has(item.id)" class="watched">已查看</div> -->
        </div>
        <div class="right-content">
          <div class="content-inner">
            <div class="item-info">
              <span class="label">
                题名：
              </span>
              <span class="value">
                {{ item.title }}
              </span>
            </div>
            <div class="item-info">
              <span class="label">
                堂号：
              </span>
              <span class="value">
                {{ item.tanghao || "无" }}
              </span>
            </div>
            <div class="item-info">
              <span class="label">
                卷数：
              </span>
              <span class="value">
                {{ item.juanshu || "无" }}
              </span>
            </div>
            <div class="item-info">
              <span class="label">
                谱籍地：
              </span>
              <span class="value">
                {{ item.pujidi || "无" }}
              </span>
            </div>
            <div class="item-info">
              <span class="label">
                编撰日期：
              </span>
              <span class="value">
                {{ item.zhuanxieriqi || "无" }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { List } from "vant";
import materialApis from "@/apis/material";
import Navbar from "@/components/h5/Navbar";

// const mockData = new Array(10).fill({}).map((item, index) => ({
//   ...item,
//   id: index + 1,
//   timing: "题目",
//   tanghao: "堂号",
//   fayuandi: "发源地",
//   zhuanxieriqi: "编撰日期",
//   price: [0, 1][index % 2],
//   cover: require("@/assets/images/search/cover.png")
// }));

export default {
  name: "MyPushu",
  components: {
    "van-list": List,
    Navbar
  },
  data() {
    return {
      page: 1,
      loading: false,
      finished: false,
      resultList: []
    };
  },
  // created() {
  //   this.fetchList();
  // },
  activated() {
    // this.refreshList();
  },
  methods: {
    async fetchList() {
      try {
        const {
          data: { material_list, max_page }
        } = await materialApis.myMaterial({ page: this.page });
        this.page += 1;
        if (this.page >= max_page) {
          this.finished = true;
        }
        this.resultList = [...this.resultList, ...material_list];
      } catch (error) {
        this.finished = true;
        console.error(error);
      }
      this.loading = false;
    },

    refreshList() {
      this.page = 1;
      this.finished = false;
      this.loading = true;
      this.resultList = [];
      this.fetchList();
    },

    navigateToDetail(id) {
      this.$router.push({
        path: `/h5/user/my-pushu/${id}`
      });
    }
  }
};
</script>

<style lang="scss">
.van-list__loading {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.my-pushu {
  width: 1125px;
  /* prettier-ignore */
  min-height: 100vh;
  // height: 2436px;
  background-color: #ffffff;

  .link {
    color: #148bfa;
  }
}

.result-list {
  width: 100%;
  padding: 0 40px 40px 40px;
  box-sizing: border-box;
}

.list-item {
  display: flex;
  position: relative;
  align-items: flex-start;
  align-self: center;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 10px;
  background-color: #f7f7f7;
  height: 400px;
  // width: 100%;
  overflow: hidden;
}

.thumb {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  // margin-top: 20px;
  // margin-right: 41px;
  // margin-left: 20px;
  border-radius: 10px;
  background-color: #8b4134;
  overflow: hidden;
  position: relative;
  height: 360px;
  width: 270px;
  margin-top: 20px;
  margin-left: 20px;
  flex: none;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    // position: absolute;
  }
}

.status {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  // margin-top: 290px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  // width: 360px;
  width: 100%;
  height: 70px;
  overflow: hidden;
  color: #ffffff;
  font-size: 40px;
  line-height: 70px;

  &.status-0 {
    color: #ff5500;
  }

  &.status-1 {
    color: #1aa97b;
  }

  &.status-2 {
    color: #f02b2b;
  }
}

.right-content {
  flex: 1;
  padding: 52px 40px;
  width: calc(100% - 290px);
  height: 100%;
  box-sizing: border-box;

  .content-inner {
    width: 100%;
    height: 100%;
  }

  .item-info {
    // margin-top: 10px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .label {
      position: relative;
      text-decoration: none;
      line-height: 1.4;
      letter-spacing: 0px;
      white-space: nowrap;
      color: #333333;
      font-size: 40px;
    }

    .value {
      position: relative;
      margin-left: 2px;
      max-width: 261px;
      // height: 34px;
      overflow: hidden;
      text-decoration: none;
      text-overflow: ellipsis;
      line-height: 1.4;
      letter-spacing: 0px;
      white-space: nowrap;
      color: #666666;
      font-size: 40px;
    }
  }
}
</style>
